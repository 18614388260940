// JavaScript Document

function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}; 


function randomIntFromInterval(min,max) // min and max included
{
    return Math.floor(Math.random()*(max-min+1)+min);
}

function isIe() {
    return window.navigator.userAgent.indexOf("MSIE ") > 0
        || !!navigator.userAgent.match(/Trident.*rv\:11\./);
}


function adjustLogo() {
   
   var wrapperWidth = $('#wrapper');
   
}

function adjustadvert() {
   
   var WinHeight = $(window).height();
   
   $('#advert .backpic').height(WinHeight-280);
   
   
   
   
   
}

function SetupAccordion() {
   
   $('.accordion').on('shown.bs.collapse', function () {

	  		var panel = jQuery(this).find('.show');
			//panel.prev().find('.oi').removeClass('oi-plus').addClass('oi-minus');
			panel.prev().addClass('active');

		  $('html, body').animate({
           
				  scrollTop: panel.offset().top - 180
		  }, 500);
			
		 //jQuery("body").getNiceScroll().resize();

		});
		
   $('.accordion').on('hide.bs.collapse', function () {

	  		
			//jQuery(this).find('.oi-minus').removeClass('oi-minus').addClass('oi-plus');
			jQuery(this).find('.card-header.active').removeClass('active');
			
		 // jQuery("body").getNiceScroll().resize();
		  
		});
   
}

function SetupThumbs() {
   
   $('#release .thumbs .cover a').on('click', function() {
      
      
      var $this = $(this);
      var $imgTarget = $('#release .cover .holde.over img');
      var $imgTargetHolder = $('#release .cover');
      
      var target = $(this).attr('data-target');
      
      console.log( $imgTargetHolder.find('.'+target));
      
      $imgTargetHolder.find('.holder').removeClass('in');
      
      $('#release .thumbs .cover a').removeClass('on');
      $this.addClass('on');
      
      setTimeout(function(){
         
         $imgTargetHolder.find('.'+target).addClass('in');
         
      },600);
      

      
   });
   
  
}

function SetupParalaxPatterns() {
   
   $('.backanipic .pattern').each(function(){
      
      $(this).addClass('on');
      
   });
   
   // setTimeout(function(){  $('.pata').addClass('on'); }, 200);
   //setTimeout(function(){  $('.patb').addClass('on'); }, 600);
   
   
}

function SetupParalaxArtwork() {
   
   console.log(isIe());
   
   var randomYpos = randomIntFromInterval(1,100);
   
   
   $('.coverart.jaralax').jarallax({
    speed: 0.1,
   imgPosition: '50% '+ randomYpos +'%',
   });
   
  // data-jarallax data-speed="0.1" data-img-position="50% <?php echo rand(1,100);?>%"
   
}

function validateRegistration() {
    

  window.addEventListener('load', function() {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(forms, function(form) {
      form.addEventListener('submit', function(event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }, false);

    
}

$(function() {
   
   if ($('.accordion').length) {
      SetupAccordion();
   }
   
   if ($('#release').length) {
      SetupThumbs();
   }
   
   if ($('#advert').length) {
     // adjustadvert();
   }
    
    if ($('#registration').length) {
        validateRegistration();

    }

   
   if ($('.coverart').length) {
      
      if (!isIe()) {
      SetupParalaxArtwork();
         
         }
   }
   
   
  
  
  
   SetupParalaxPatterns() ;
   
   
   
   
   $('#hamburger').on('click', function(){
      
     
      var $but = $(this);
      
      if ( $('#menuholder').hasClass('open')) {
         
         $but.removeClass('close');
         $('#menuholder').removeClass('open');
         
         
      } else {
       
         $but.addClass('close');
         $('#menuholder').addClass('open');
      }
      
      
     
   })
   
});

var myEfficientFn = debounce(function() {
	
}, 250);

window.addEventListener('resize', myEfficientFn);

